import React from 'react'
import '../HomeStyle/EmpoweringEducators.css'
import Img1 from '../Assets/empoweringEducator1.jpg'
import Img2 from '../Assets/empoweringEducator2.jpg'

const EmpoweringEducators = () => {
    return (
        <>
            <div className='empoweringEducators_container'>
                <h1 className='empoweringEducators_heading'>Empowering Educators</h1>
                <p className='empoweringEducators_para'>We are committed to supporting and empowering educators with the tools, resources, and professional development opportunities they need to deliver high-quality instruction.</p>
                <div className='educatorCardContainer'>
                    {empoweringEducatorData.map((elm,index) => {
                        return <EmpoweringEducatorCard data={elm} key={index}/>
                    })}
                </div>
            </div>
        </>
    )
}

export default EmpoweringEducators

const EmpoweringEducatorCard = ({ data }) => {
    const { heading, para, image } = data;
    return (
        <>
            <div style={{margin: '1rem'}}>
                <div style={{ backgroundImage: `url(${image})` }} className='educatorCardImage'></div>
                <h1 className='educatorCard_Heading'>{heading}</h1>
                <p  className='educatorCard_Para'>{para}</p>
            </div>
        </>
    )
}

const empoweringEducatorData = [
    {
        heading: 'Technology Integration',
        para: 'Provide educators with access to innovative technologies and digital learning tools to enhance instruction and student engagement.',
        image: Img1
    },
    {
        heading: 'Professional Development',
        para: 'Offer ongoing professional development opportunities to ensure educators are equipped with the latest teaching methodologies and industry insights.',
        image: Img2
    },
]