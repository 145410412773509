import React from 'react'
import '../HomeStyle/IndustryPartnerships.css'

const IndustryPartnerships = () => {
  return (
    <>
      <div className='industryPartnerships_container'>
        <h1 className='industryPartnerships_heading'>Industry Partnerships</h1>
        <p className='industryPartnerships_para'>We foster strong partnerships with leading industries to ensure that our programs are aligned with current trends, emerging technologies, and real-world challenges. These partnerships also provide students with valuable mentorship opportunities, industry exposure, and networking connections.</p>
        <div className='partnershipCard_container'>
          {IndustryPartnershipData.map((elm, index) => {
            return <IndustryPartnershipCard data={elm} key={index} index={index} />
          })}
        </div>
      </div>
    </>
  )
}

export default IndustryPartnerships

const IndustryPartnershipCard = ({ data, index }) => {
  const { heading, para } = data;
  return (
    <>
      <div style={{width: '50%'}}>
        <h1 className='partnershipCardHeading'>{heading}</h1>
        <p className='partnershipCardPara'>{para}</p>
      </div>
    </>
  )
}

const IndustryPartnershipData = [
  {
    heading: 'Advisory Boards',
    para: 'Industry experts provide guidance and feedback on curriculum development, ensuring alignment with industry needs.'
  },
  {
    heading: 'Mentorship Programs',
    para: 'Students connect with industry professionals who provide guidance, support, and career advice.'
  },
  {
    heading: 'Industry Visits',
    para: 'Excursions to industry sites provide students with first-hand exposure to real-world applications and industry practices.'
  },
  {
    heading: 'Guest Speakers',
    para: 'Industry leaders share their expertise and insights, providing students with valuable perspectives and career inspiration.'
  },
]