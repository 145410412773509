import React, { useState } from 'react'
import '../HomeStyle/HandsOnLearning.css'
import { GoTriangleRight } from "react-icons/go";

const HandsOnLearning = () => {
  return (
    <>
      <div className='handsOnLearning_container'>
        <h1 className='handsOnLearning_heading'>Hands-on Learning</h1>
        <p className='handsOnLearning_para'>We believe in the power of hands-on learning to solidify understanding and develop practical skills. Our programs provide ample opportunities for students to apply their knowledge in real-world scenarios.</p>
        <div>
          {HandsOnLearningData.map((elm, index) => {
            return <HandsOnLearningCard data={elm} key={index} />
          })}
        </div>
      </div>
    </>
  )
}

export default HandsOnLearning

const HandsOnLearningCard = ({ data }) => {
  const [showPara, setShowPara] = useState(false)
  const { heading, para } = data;

  return (
    <>
      <div>
        <div style={{ display: 'flex', margin: '2.5rem 0' }}>
          <GoTriangleRight className='learningCardIcon' style={showPara ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0deg)' }} onClick={() => setShowPara(!showPara)}/>
          <p className='learningCardPara'>{heading}</p>
        </div>
        <div style={showPara ? { display: 'flex' } : { display: 'none' }}>
          <div className='deployCardLine'></div>
          <p className='learningCardPara'>{para}</p>
        </div>
      </div>
    </>
  )
}

const HandsOnLearningData = [
  {
    heading: 'Laboratory Experiences',
    para: 'Students gain hands-on experience with industry-standard equipment and tools, developing technical proficiency and practical skills.'
  },
  {
    heading: 'Industry Internships',
    para: 'Immersive work experiences in real-world settings, allowing students to apply their knowledge and gain valuable insights into industry practices.'
  },
  {
    heading: 'Project-Based Assignments',
    para: 'Complex projects that require students to collaborate, solve problems, and apply their knowledge in a practical context.'
  },
]