import React from 'react'
import '../HomeStyle/IndustryNeed.css'

const IndustryNeed = () => {
  return (
    <>
      <div className='industryNeed_container'>
        <h1 className='industryNeed_heading'>In a Globalized World, Education Must Have Universal Applicability Aligned to the Needs of the Industry</h1>
        <p className='industryNeed_para'>We recognize the importance of preparing students for a globalized workforce. Our programs are designed to be adaptable and relevant across diverse geographical locations and cultural contexts, fostering a globally competent and competitive workforce.</p>

        <div style={{ display: "flex" }}>
          <div className='industryNeed_card'>
            <h1 style={{fontSize: '2.25rem'}}>Intercultural Collaboration</h1>
            <p className='industryNeed_para'>Develop students' understanding of global cultures and practices, promoting effective communication and collaboration across diverse teams.</p>
          </div>

          <div className='industryNeed_card' style={{padding: '0 1.5rem'}}>
            <h1 style={{fontSize: '2.25rem'}}>Global Perspective</h1>
            <p className='industryNeed_para'>Expose students to diverse perspectives, fostering a global mindset and an appreciation for different ways of thinking and working.</p>
          </div>

          <div className='industryNeed_card'>
            <h1 style={{fontSize: '2.25rem'}}>International Opportunities</h1>
            <p className='industryNeed_para'>Provide students with opportunities to engage in international internships, research projects, or study abroad programs, expanding their horizons and global competence.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndustryNeed
