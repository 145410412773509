import React from 'react'
import './Home.css'
import Hero from './HomeComponent/Hero'
import IndustryProgram from './HomeComponent/IndustryProgram'
import IndustryNeed from './HomeComponent/IndustryNeed'
import OurApproach from './HomeComponent/OurApproach'
import InnovativeCurriculomn from './HomeComponent/InnovativeCurriculomn'
import HandsOnLearning from './HomeComponent/HandsOnLearning'
import IndustryPartnerships from './HomeComponent/IndustryPartnerships'
import FlexibleDelievery from './HomeComponent/FlexibleDelievery'
import EmpoweringEducators from './HomeComponent/EmpoweringEducators'
import ReadyToDeploy from './HomeComponent/ReadyToDeploy'

const Home = () => {
  return (
    <>
    <Hero />
    <IndustryProgram />
    <IndustryNeed />
    <ReadyToDeploy />
    <OurApproach />
    <InnovativeCurriculomn />
    <HandsOnLearning />
    <IndustryPartnerships />
    <FlexibleDelievery />
    <EmpoweringEducators />
    </>
  )
}

export default Home