import React from 'react'
import './College.css'

const College = () => {
  return (
    <>
      <div className='college_container'>
        <header className='college_header'>
          <h1>Industry Alignment that Brings Students Closer to Jobs</h1>
        </header>

        <div class="container">
          <section class="section">
            <div class="highlight_college">
              As the changes in the industry outpace curriculum updates in colleges, the gap between the industry requirements and the education that students receive from many colleges needs to be filled.
            </div>
            <p>By collaborating with MindMatrix Labs, colleges incorporate priorities of the industry through specialized industry-aligned programs.</p>
            <p>As part of this program, students gain access to technological advancements, industry priorities, domain knowledge, and best practices that the industries follow. Moreover, through the program, students develop a wide range of supporting skills such as critical thinking, teamwork, analytical reasoning, and research.</p>
          </section>

          <section class="section">
            <h2>How Can We Help?</h2>
            <p>At MindMatrix Labs, we are pioneers in developing and running industry-aligned programs. Our certification programs run in parallel with the existing B.Tech courses, and graduating students are awarded certificates from the industry partner in addition to the ones they get from the university.</p>
          </section>

          <section class="section">
            <h2>Programs Offered</h2>
            <h3>The 4-year Parallel Program</h3>
            <p>Our Extended Certification Parallel Program is designed to run in parallel with the existing B.Tech curriculum. Students from all specializations can join these programs for enhanced learning as an optional course. Our industry partner awards the certificate to the students on successful completion of this program.</p>

            <ul class="program-list">
              <li>Cloud Computing</li>
              <li>Mainframe Computing</li>
              <li>Business Analytics</li>
              <li>Telecom Informatics</li>
              <li>Open Source and Open Standards</li>
              <li>IT Infrastructure</li>
              <li>BFSI, Graphics, and Gaming</li>
              <li>Cyber Security</li>
              <li>Mobile Computing and IoT</li>
            </ul>

            <h3>The 1-year Short-Term Certificate Program</h3>
            <p>This program allows students to gain essential understanding of the core concepts and follows a structure similar to the 4-year program. The certificate is awarded upon successful completion.</p>
          </section>

          <section class="section contact-box-college">
            <h2>Enhanced Education Can Be Your Choice Today</h2>
            <p>Connect with our team today and find out how:</p>
            <p><strong>+91-80-41216877</strong></p>
            <p><strong>contact@mindmatrixlabs.com</strong></p>
          </section>

          <section class="section">
            <h2>PLeX Score - The Employability Index</h2>
            <p>MindMatrix has developed a patented method of tracking and improving knowledge and the key industry-needed skills of the students. The PLeX score is an indicator of the students' fitment for the types of jobs offered by the industry.</p>
          </section>
        </div>

        <footer class="footer">
          <p>&copy; 2025 MindMatrix Labs. All rights reserved.</p>
        </footer>
      </div>
    </>
  )
}

export default College
