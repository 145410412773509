import React from 'react'
import '../HomeStyle/InnovativeCurriculomn.css'

const InnovativeCurriculomn = () => {
  return (
    <>
      <div className='innovativeCurriculomn_container'>
        <h1 className='innovativeCurriculomn_heading'>Innovative Curriculum</h1>
        <p className='innovativeCurriculomn_para'>Our curriculum is designed to be dynamic, engaging, and relevant to the evolving needs of the industry. We incorporate cutting-edge technologies, innovative teaching methodologies, and real-world case studies to create a rich and interactive learning experience.</p>
        <div>
          {InnovativeCurriculomnData.map((elm, index) => {
            return <InnovativeCurriculomnCard data={elm} key={index} index={index}/>
          })}
        </div>
      </div>
    </>
  )
}

export default InnovativeCurriculomn;

const InnovativeCurriculomnCard = ({ data, index }) => {
  const { heading, para } = data;
  
  return (
    <>
      <div className='curriculomnTable'>
          <p>{heading}</p>
          <p>{para}</p>
      </div>
    </>
  )
}

const InnovativeCurriculomnData = [
  {
    heading: "Adaptive Learning",
    para: "Personalized learning paths based on individual student needs and progress.",
  },
  {
    heading: "Interactive Content",
    para: "Engaging multimedia elements, simulations, and interactive exercises to enhance learning.",
  },
  {
    heading: "Project-Based Learning",
    para: "Real-world projects that apply theoretical knowledge and develop practical skills.",
  },
  {
    heading: "Industry-Specific Modules",
    para: "Focused curriculum tailored to specific industry sectors and job roles.",
  },
]