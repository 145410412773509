import React from 'react'
import '../HomeStyle/Hero.css'

const Hero = () => {
  return (
    <>
      <div className='hero_container'>
        <h1 className='hero_heading '>Breaking Barriers and Transforming the Learning Experience staging pr</h1>
        <p className='hero_para'>Bridging the gap between education and industry, empowering students with the skills they need to succeed in the modern workforce.</p>
        <button className='hero_btn'>Learn More</button>
      </div>
    </>
  )
}

export default Hero
