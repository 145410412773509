import React from 'react'
import '../HomeStyle/FlexibleDelievery.css'

const FlexibleDelievery = () => {
  return (
    <>
      <div className='flexibleDelievery_container'>
        <div className='flexibleDelievery_container_left'>
          <h1 className='flexibleDelievery_heading'>Flexible Delivery</h1>
          <p className='flexibleDelievery_para'>We understand that learners have different needs and preferences. Our programs are offered through a range of delivery modalities, allowing students to choose the learning format that best suits their individual circumstances.</p>
          <div>
            {FlexibleDelieveryData.map((elm, index) => {
              return <FlexibleDelieveryCard data={elm} key={index}/>
            })}
          </div>
        </div>
        <div className='flexibleDelievery_container_right'></div>
      </div>
    </>
  )
}

export default FlexibleDelievery

const FlexibleDelieveryCard = ({data}) => {
  const { heading, para} = data;
  return (
    <>
      <h1 className='delieveryCardHeading'>{heading}</h1>
      <p className='delieveryCardPara'>{para}</p>
    </>
  )
}

const FlexibleDelieveryData = [
  {
    heading: 'Online Learning',
    para: 'Flexible and accessible online courses delivered through interactive platforms and learning management systems.',
  },
  {
    heading: 'Blended Learning',
    para: 'A combination of online and in-person instruction, providing a dynamic and engaging learning experience.',
  },
  {
    heading: 'On-Campus Programs',
    para: 'Traditional on-campus programs for students who prefer a structured and immersive learning environment.',
  },
]