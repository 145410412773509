import React from 'react'
import '../HomeStyle/IndustryProgram.css'

const IndustryProgram = () => {
  return (
    <>
      <div className='industryProgram_container'>
        <h1 className='industryProgram_heading'>Bringing Industry-Aligned Programs to Educational Institutions</h1>
        <p className='industryProgram_para'>We partner with leading industries to develop and deliver curriculum that reflects current trends, emerging technologies, and real-world challenges. This ensures that graduates are equipped with the knowledge and skills necessary to seamlessly transition into the workforce.</p>

        <div style={{ display: "flex", width: "100%" }}>
          <div className='industryProgram_card'>
            <h1 style={{fontSize: '2.25rem'}}>Relevant Skills</h1>
            <p className='industryProgram_para'>Equip students with the specific skills required by industries, closing the skills gap and ensuring graduates are work-ready.</p>
          </div>

          <div className='industryProgram_card' style={{margin: '0 2rem'}}>
            <h1 style={{fontSize: '2.25rem'}}>Practical Application</h1>
            <p className='industryProgram_para'>Integrate real-world projects and case studies into the curriculum, providing hands-on experience and practical application of learned concepts.</p>
          </div>

          <div className='industryProgram_card'>
            <h1 style={{fontSize: '2.25rem'}}>Industry Mentorship</h1>
            <p className='industryProgram_para'>Connect students with industry professionals who can provide guidance, mentorship, and networking opportunities.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndustryProgram
