import React from 'react'
import '../HomeStyle/ReadyToDeploy.css'

const ReadyToDeploy = () => {
  return (
    <>
      <div className='readyToDeploy_container'>
        <h1 className='readyToDeploy_heading'>Ready-to-Deploy Knowledge Enhances the Productivity of the Industry and Improves the Employability of the Fresh Graduates</h1>
        <p className='readyToDeploy_para'>We believe that education should be a catalyst for positive change in the industry. Our programs empower graduates with the skills and knowledge they need to make an immediate impact in their roles, contributing to the growth and success of their organizations.</p>

        <div className='deployCard_container'>
          {deployData.map((elm,index) => {
            return <DeployCard data={elm} key={index}/>
          })}
        </div>
      </div> 
    </>
  )
}

export default ReadyToDeploy

const DeployCard = ({ data }) => {
  const { heading, para } = data;
  return (
    <>
      <div className='deployCard'>
        <h1 className='deploayCardHeading'>{heading}</h1>
        <p className='deployCardPara'>{para}</p>
      </div>
    </>
  )
}

const deployData = [
  {
    heading: 'Industry Relevance',
    para: 'Curriculum designed to meet the specific needs and requirements of the industry, ensuring graduates are well-prepared for their roles.',
  },
  {
    heading: 'Enhanced Productivity',
    para: 'Graduates enter the workforce with the skills to contribute effectively, driving productivity and innovation within organizations.',
  },
  {
    heading: 'Improved Employability',
    para: 'Highly sought-after skills and knowledge increase graduates employability, leading to better job opportunities and career prospects.',
  },
]