import React from 'react'
import './App.css'
import { Routes, Route} from 'react-router-dom'
// import LandingPage from './Component/LandingPage/LandingPage'
// import DropDownSearch from './Component/DropDownSearch/DropDownSearch'
import Home from './Component/Home/Home'
import Navbar from './Component/Navbar/Navbar'
import Student from './Component/Student/Student'
import College from './Component/College/College'
import About from './Component/About/About'

const App = () => {
  return (
    <>
    <Navbar />
      <div className='App'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/student' element={<Student />} />
          <Route path='/college' element={<College />} />
          <Route path='/about' element={<About />} />
          {/* <Route path='/search' element={<DropDownSearch />} /> */}
        </Routes>
      </div>
    </>
  )
}

export default App