import React from 'react'
import './Student.css'

const Student = () => {
    return (
        <div>
            <header>
                <h1>Learning for a Progressive Future</h1>
            </header>

            <div class="container">
                <section class="section">
                    <h2>Stimulating Application-Based Learning Ecosystem</h2>
                    <p>Do you want to know about the latest developments in the industry and the technologies currently being used to execute work? Are you familiar with the concepts and working methodologies being used by companies? Do you have a sound understanding of the industry domain?</p>
                    <p>This is where MindMatrix Labs comes in. Our programs follow a mixed-mode of learning, bringing industry knowledge to you.</p>
                </section>

                <section class="section">
                    <h2>List of Offerings by MindMatrix Labs</h2>
                    <ul>
                        <li>B. Tech Program</li>
                        <li>Parallel 4-Year Extended Certification Program</li>
                        <li>Short-Term Certification Program</li>
                        <li>PLEX Score &mdash; an employability indicator</li>
                    </ul>
                </section>

                <section class="section highlight">
                    <h2>PLEX Scores</h2>
                    <p>MindMatrix Labs has established a unique mechanism to measure a student's competencies based on a unique methodology available on our learning platform. PLEX stands for Participate, Learn, and Explore.</p>
                    <p>The PLEX score is a standardized indicator used to measure the employability of students in their area of specialization.</p>
                </section>

                <section class="section">
                    <h2>Benefits</h2>
                    <ul>
                        <li>An institutionalized PLEX score helps employers differentiate between candidates.</li>
                        <li>It provides employers with an understanding of the strength and characteristics of a student.</li>
                        <li>It helps students focus on certain areas of improvement and get job-ready.</li>
                        <li>It facilitates college placements.</li>
                    </ul>
                </section>

                <section class="section flex-container">
                    <div class="box-student">
                        <h3>How Will You Benefit as a Student?</h3>
                        <p>As Steve Jobs puts it, "Innovation distinguishes between a leader and a follower."</p>
                        <p>Our programs provide the tools to lead and innovate.</p>
                    </div>

                    <div class="box-student">
                        <h3>Contact Us</h3>
                        <p>We help you acquire the right skills to get hired.</p>
                        <p><strong>Contact:</strong> +91-80-41216877<br />contact@mindmatrixlabs.com</p>
                    </div>
                </section>

                <section class="section">
                    <h2>How MindMatrix Labs Helps Students Through Transformational Education</h2>
                    <ul>
                        <li>Industry-certified programs enhance your capability.</li>
                        <li>Get knowledge of the latest technologies.</li>
                        <li>Prepare for the corporate environment.</li>
                        <li>Earn an increased employability score.</li>
                        <li>Participate in innovation and research projects.</li>
                        <li>Experience almost real-life project scenarios.</li>
                        <li>Receive mentoring from industry professionals.</li>
                    </ul>
                </section>
            </div>

            <footer>
                <p>&copy; 2025 MindMatrix Labs. All rights reserved.</p>
            </footer>
        </div>
    )
}

export default Student
