import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <>
            <div className='navbar_container'>
                <div className="navbar_left"></div>
                <div className="navbar_right">
                    <Link className='navbar_icon' to='/'>Home</Link>
                    <Link className='navbar_icon' to='/college'>Colleges</Link>
                    <Link className='navbar_icon' to='student'>Students</Link>
                    {/* <Link className='navbar_icon' to='/about'>About</Link> */}
                    {/* <Link className='navbar_icon' to='/contact'>Contact Us</Link> */}
                </div>
            </div>
        </>
    )
}

export default Navbar
