import React from 'react'
import '../HomeStyle/OurApproach.css'
import { LuBrain } from "react-icons/lu";
import { TbHandStop } from "react-icons/tb";
import { FaRegHandshake } from "react-icons/fa";
import { BsClock } from "react-icons/bs";

const OurApproach = () => {
  return (
    <>
      <div className='ourApproach_container'>
        <div className='ourApproach_container_left'></div>
        <div className='ourApproach_container_right'>
          <h1 className='ourApproach_heading'>Our Approach</h1>
          <p className='ourApproach_para'>Our approach to education is guided by a commitment to innovation, collaboration, and student-centric learning experiences.</p>
          <div className='approachContainer_card'>
            {OurApproachContainerData.map((elm, index) => {
              return <OurApproachContainerCard data={elm} key={index} />
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default OurApproach;

const OurApproachContainerCard = ({ data }) => {
  const { Icon, heading, para } = data;
  return (
    <>
      <div style={{margin: '2rem 0'}}>
        <Icon className='approachCardIcon'/>
        {/* <div className='approachCardIcon'>{Icon}</div> */}
        <h1 className='approachCardHeading'>{heading}</h1>
        <p className='approachCardPara'>{para}</p>
      </div>
    </>
  )
}

const OurApproachContainerData = [
  {
    heading: "Innovative Curriculum",
    para: "Develop a dynamic and engaging curriculum that incorporates cutting-edge technologies and teaching methodologies.",
    Icon: LuBrain 
  },
  {
    heading: "Hands-on Learning",
    para: "Provide ample opportunities for practical application, project-based learning, and real-world simulations.",
    Icon: TbHandStop
  },
  {
    heading: "Industry Partnerships",
    para: "Collaborate with leading industries to ensure curriculum alignment, provide mentorship opportunities, and facilitate industry exposure.",
    Icon: FaRegHandshake
  },
  {
    heading: "Flexible Delivery",
    para: "Offer a range of learning modalities, including online, blended, and in-person options, to cater to diverse learning styles and preferences.",
    Icon: BsClock
  }
]